$container-lg: 1280px;
$navbar-height: 80px;
$navbar-border-radius: none;
$navbar-collapse-height: 100%;

@import "../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap";

@-webkit-keyframes hvr-pulse {
    25% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    75% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}
@keyframes hvr-pulse {
    25% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    75% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}

@keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@keyframes roll {
  from { background-position: 0 0; }
  to { background-position: 100% 0; }
}

@keyframes bounce {
  from { transform: translate(0, 0px); z-index: -1; }
  65% { transform: translate(0, 5px); z-index: -1; }
  to { transform: translate(0, -0px); z-index: -1; }    
}

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
body {
  background: url('../../images/noise.png') #eeefee;
  width:100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

.navbar-contrast {
  font-family: cabinregular;  
  background: #000;
  text-align: center;
  .navbar-nav {
    display: table;
    margin: 0 auto;
    float: none;
  }
  ul {
    li {
      margin-right: 25px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background: transparent;
        a {
          background: transparent;
        }
      }
    }
  }
  a {
    font-size: 18pt;
    color: #fff;
  }
  .btn.btn-danger {
    font-family: cabinbold;  
    display: inline-block;
    width: 120px;
    height: 45px;
    line-height: 45px;
    vertical-align: middle;
    padding: 0;
    background: #ec2829;
    border: none;
    text-align: center;
    border-radius: 10px;
    font-size: 18pt;
    margin: 0;
    margin-top: 15px;
    float: right;
  }
}

h2 {
  font-family: alfa_slab_oneregular;
  font-size: 28pt;
  color: #000; 
  line-height: 45px; 
}

#intro {
  margin-top: 140px;
  .btn.btn-primary {
    font-family: cabinregular;
    background: url('../../images/intro-amazon-logo.png') #000 no-repeat center 50px;
    width: 300px;
    height: 100px;
    border-radius: 50px;
    border: none;
    font-size: 18pt;
    line-height: 45px;
    margin-top: 50px;
  }
  .book {
    position: relative;
    height: 531px;
    background: url('../../images/intro-book.png') no-repeat;
    margin-top: 0;
    // animation: bounce 5s linear infinite;

    &:after {
      position: absolute;
      top: 480px;
      left: -100px;
      display: block;
      content: " ";
      width: 552px;
      height: 127px;
      background: url('../../images/intro-book-stand.png') no-repeat;
      z-index: -1;
    }
  }

  .tower-hospital {
    position: absolute;
    top: 0;
    left: 250px;
    width: 482px;
    height: 951px;
    background: url('../../images/future-hospital.png') no-repeat;
    z-index: -2;
  }
}

@media (max-width: $screen-sm-min) {
  html, body {
    overflow: hidden;
    overflow-y: auto;
  }
  #intro {
    position: relative;
    z-index: 1;
    width: 100%;
    margin-top: 80px;
    .container {
      width: 100%;
      .row {
        width: 100%;
      }
    }
    .col-xs-12.col-sm-12.col-md-6.col-lg-6:nth-child(2) {
      margin-top: 50px;
    }
    h2 {
      font-size: 14pt;
      line-height: 14pt;
    }
    .book {
      margin-top: 50px;
      height: 200px;
      background-size: 70%;
      &:after {
        top: 190px;
        left: -30px;
        background-size: 40%;
      }
    }
    .tower-hospital {
      top: -120px;
      left: 100px;
      width: 180px;
      height: 330px;
      background-size: 100%;
    }
    .amazon {
      margin-top: 100px;
      margin-bottom: -50px;
      text-align: center;
      .btn {
        display: table;
        margin: 0 auto;
        margin-left: -20px;
      }
    }
  }
}

#trailer {
  position: relative; 
  background: url('../../images/wide/trailer-background.png') transparent no-repeat center center;
  margin-top: -300px;
  height: 1500px;
  &::before {
    position: absolute;
  }
  .col-sm-12 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }
  .play-button {
    cursor: pointer;
    display: table;
    margin: 0 auto;
    vertical-align: middle;
    content: " ";
    border-right: 0 double transparent;
    border-left: 65px solid #ff7171;
    border-bottom: 48px double transparent;
    border-top: 48px double transparent;
    width: 0;
    height: 0;
  }
  h3 {
    display: table;
    font-family: alfa_slab_oneregular;
    margin: 45px auto 80px;
    color: #fff;
    font-size: 31px;
  }
  p {
    display: table;
    font-family: cabinregular;
    margin: 25px auto 15px;
    color: #fff;
    font-size: 20px;
  }
  .btn-group-justified {
    width: 60% !important;
    margin: 0 auto !important;
    border-top: 1px solid #fff;
    padding-top: 25px;
    margin-top: 25px; 
    .btn.btn-link {
      color: #fff;
      .fa {
        font-size: 25px;
        color: #fff;
      }
    }
  }
}

@media (max-width: $screen-sm-min) {
  #trailer {
    width: 100%;
    .container {
      width: 100%;
      .col-sm-12 {
        width: 100%;
      }
    }
  }
}

#testimonials {
  margin-top: -150px;
  h2 {
    display: table;
    margin: 0 auto 50px;
  }
  .carousel {
    .carousel-inner {
      .item {
        min-height: 350px;
        margin-top: 50px;
        img {
          display: table;
          margin: 0 auto;
        }
        .carousel-caption {
          position: relative;
          display: table;
          margin: 25px auto;
          text-align: center;
          color: #000;
          font-family: cabinregular;
          font-style: italic;
          text-shadow: none;
          font-size: 14pt;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          .name {
            display: block; 
            margin-top: 25px;
            font-family: cabinbold;
            font-size: 1.2em;
            font-style: normal;
          }
        }
      }
    }
    .carousel-indicators {
      li {
        border-color: #000;
        background-color: #000;
        &.active {
          border-color: #e62b1e;
          background-color: #e62b1e;
        }
      }
    }
    .carousel-control {
      top: 50%;
      transform: translateY(-50%);
      text-shadow: none;
      &.left, &.right {
        background: none;
        text-shadow: none;
        .fa {
          color: #e62b1e;
          font-size: 120px;
          text-shadow: none;
        }
      }
    }
  }
}

@media (max-width: $screen-sm-min) {
  #testimonials {
    width: 100%;
    margin-top: -300px;
    .container {
      width: 100%;
      .col-sm-12 {
        width: 100%;
        h2 {
          font-size: 18pt;
          line-height: 18pt;
        }
        .carousel.slide {
          margin-top: -50px;
          margin-bottom: 50px;
        }
        a.carousel-control {
          transform: translateY(-80%);
        }
      }
    }
  }
}


#logos {
  position: relative;
  background: #fff;
  margin-top: 100px;
  &:before {
    position: absolute;
    display: inline-block;
    content: "";
    width: 100%;
    height: 212px;
    top: -212px;
    left: 0;
    background: url('../../images/wide/logos-background.png') no-repeat center center;
    background-size: cover;
  }
}

@media (max-width: $screen-sm-min) {
  #logos {
    width: 100%;
    .container {
      width: 100%;
      .row {
        width: 100%;
        h2 {
          font-size: 18pt;
          line-height: 18pt;
        }
      }
    }
    .btn-group.btn-group-justified.text-center {
      .btn {
        display: table;
        margin: 15px auto;
      }
    }
  }
}

#table-of-content {
  position: relative;
  &:before {
    position: absolute;
    display: inline-block;
    content: "";
    width: 100%;
    height: 340px;
    top: 0;
    left: 0;
    background: url('../../images/wide/table-content-top-background.png') #fff no-repeat center center;
    background-size: cover;
  }
  .row:first-child .container .row {
    background: none;
    position: relative;
    h2 {
      position: absolute;
      top: 150px;
      right: 200px;
      color: #fff;
      font-size: 22pt;
      z-index: 1;
    }
  }
  .row {
    position: relative;
    height: 100%;
    &.white {
      position: relative;
      background: #fff;
      &:after {
        content: "";
        width: 100%;
        height: 211px;
        position: absolute;
        bottom: -210px;
        left: 0;
        background: url('../../images/wide/table-content-white-background.png') #fff no-repeat center center;
        background-size: cover;
        z-index: -1;
      }
    }
    &.black {
      position: relative;
      .container {
        background: #000;
      }
      &:after {
        content: "";
        width: 100%;
        height: 162px;
        position: absolute;
        bottom: -160px;
        left: 0;
        background: url('../../images/wide/table-content-black-background.png') #fff no-repeat center center;
        background-size: cover;
        z-index: -1;
      }
    }
    &:nth-child(2) {
      margin-top: 339px;
      margin-bottom: 5px;
    }
    &:nth-child(3) {
      margin-top: 160px;
      margin-bottom: 200px;
    }
    h2 {
      margin: 34px 0 0 50px;
      color: #ff7171;
      font-size: 22pt;
    }
    h3 {
      font-family: alfa_slab_oneregular;
      color: #000;
      font-size: 22pt;
      margin-top: 30px;
      margin-bottom: 60px;
    }
    p {
      color: #000;
      font-family: cabinregular;
      font-size: 14pt;
    }
    &.black {
      h3 {
        margin-top: 80px;
        color: #fff;
      }
      p {
        color: #fff;
      }
    }
    img+h2 {
      position: absolute;
      top: -70px;
      right: 50px;
    }
    img {
      width: 100%;
    }
    &.black {
      background: #000;
      &:last-child {
        padding-top: 100px;
        &:after {
          height: 0;
          background: none;
        }
        h3 {
          margin-top: 0;
        }
      }
      img+h2 {
        position: absolute;
        top: -50px;
        right: -50px;
        left: 0;
      }
    }
  }
}

@media (max-width: $screen-sm-min) {
  #table-of-content {
    width: 100%;
    .row:first-child .container .row h2 {
      left: -40px;
      top: 150px;
      font-size: 22pt;
      width: 100%;
      text-align: center;
    }
    .row.black img+h2 {
      top: -80px;
      left: -20px;
      font-size: 22pt;
    }
    .row.white img+h2 {
      top: -30px;
      right: 20px;
      font-size: 22pt;
    }
    .carousel .carousel-inner .item .carousel-caption .title {
      font-size: 14pt;
    }
    .carousel a.carousel-control .fa {
      font-size: 80px;
    }
  }
}

#news {
  position: relative;
  background: url('../../images/geometry_pattern.8b9eb18d.png');
  padding-top: 160px;
  padding-bottom: 160px;
  &:before {
    position: absolute;
    top: 0;
    left: 0; 
    display: inline-block;
    content: "";
    width: 100%;
    height: 270px;
    background: url('../../images/wide/table-content-last-black-background.png') no-repeat center center;
    background-size: cover;
  }
  h2 {
    display: table;
    margin: 0 auto;
  }
  .carousel {
    .carousel-inner {
      .item {
        margin-top: 50px;
        .carousel-caption {
          width: 60%;
          position: relative;
          display: table;
          margin: 25px auto 50px;
          text-align: left;
          color: #000;
          font-family: cabinregular;
          font-style: italic;
          text-shadow: none;
          font-size: 14pt;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          img {
            display: table;
            margin: 0 auto;
          }
          .btn-danger {
            font-family: cabinregular;
            display: table;
            margin: 25px auto 0;
            background: #ff7171;
            border: none;
            border-radius: 20px;
            font-style: normal;
            font-size: 14pt;
          }
          .title {
            display: block; 
            margin-top: 25px;
            font-family: cabinbold;
            font-size: 1.4em;
            font-style: normal;
          }
        }
      }
    }
    .carousel-indicators {
      li {
        border-color: #000;
        background-color: #000;
        &.active {
          border-color: #e62b1e;
          background-color: #e62b1e;
        }
      }
    }
    .carousel-control {
      top: 50%;
      transform: translateY(-50%);
      text-shadow: none;
      &.left, &.right {
        background: none;
        text-shadow: none;
        .fa {
          color: #e62b1e;
          font-size: 120px;
          text-shadow: none;
        }
      }
    } 
  }
}

@media (max-width: $screen-sm-min) {
  #news {
    width: 100%;
    .carousel .carousel-inner .item .carousel-caption {
      width: 80%;
    }
    .carousel .carousel-inner .item .carousel-caption .title {
      font-size: 14pt;
    }
    .carousel .carousel-inner .item .carousel-caption .content {
      font-size: 12pt;
    }
    .carousel .carousel-control .fa {
      font-size: 70px !important;
    }
    .carousel .carousel-control.right {
      right: -30px;
    }
    .carousel .carousel-control.left {
      left: -30px;
    }
  }
}

#about-the-author {
  position: relative;
  height: 100%;
  padding-top: 118px; 
  background: #fff; 
  margin-bottom: 420px;
  &:before {
    position: absolute;
    top: -205px;
    left: 0;
    content: "";
    width: 100%;
    height: 542px;
    background: url('../../images/wide/author-before-background.png') center center no-repeat; 
    background-size: cover;
  }
  .row {
    height: 100%;
    h2 {
      display: table;
      width: 100%;
      text-align: center;
    }
    .author {
      position: relative;
      display: table;
      margin: 50px auto 0;
      .profile-image {
        position: relative;
        width: 330px;
        height: 349px;
        background: url('../../images/author.png') no-repeat;
        z-index: 1;
      }
      .ekg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 600px;
        height: 49px;
        background: url('../../images/ekg.gif') repeat-x;
        background-position: 0 0;
        transform: translate(-50%, -50%);
        z-index: 0;
        animation: roll 8s linear infinite;
      }
    }
    h4 {
      font-size: 18pt;
      font-weight: bold;
      margin-top: 40px;
    }
    .btn-group {
      margin: 40px auto;
      .btn {
        color: #ec2829;
        font-size: 25px;
      }
    }
    .col-sm-12.col-lg-4.col-lg-offset-4 {
      text-align: center;
    }
    .col-sm-12.col-lg-6.col-lg-offset-3 {
      font-family: cabinregular;
      font-size: 14pt;
    }
    .after-line {
      &:after {
        display: block;
        content: "";
        width: 80px;
        height: 1px;
        background: #ec2829;
        margin: 25px 0;
      }
    }
  }
}

@media (max-width: $screen-sm-min) {
  #about-the-author {
    width: 100%;
    &:before {
      width: 100%;
    }
    .row .author {
      display: table;
      margin: 0 auto;
    }
    .row .author .profile-image {
      display: table;
      margin: 0 auto;
      background-size: 80%;
      height: 275px;
      max-width: 320px;
    }
    .row .author .ekg {
      width: 100%;
      left: 35%;
    }
  }
}

.footer {
  position: relative;
  background: #000;
  &:before {
    position: absolute;
    top: -430px;
    left: 0;
    display: block;
    content: " ";
    width: 100%;
    height: 541px;
    background: url('../../images/wide/footer-background.png') #fff no-repeat center center;
    background-size: cover;
  }
  .row {
    position: relative;
    padding-top: 250px;
    background: #000;
    min-height: 540px;
  }
  h2 {
    position: absolute;
    top: -50px;
    left: 50px;
    color: #fff;
    z-index: 1;
  }
  .btn-amazon {
    font-family: cabinregular;
    background: url('../../images/intro-amazon-logo.png') #ec2829 no-repeat center 50px;
    width: 300px;
    height: 100px;
    border-radius: 50px;
    border: none;
    font-size: 18pt;
    line-height: 45px;
    margin-top: 50px;
    margin-left: 35px;
  }
  .book {
    position: absolute;
    top: -200px;
    left: 0;
    width: 349px;
    height: 491px;
    background: url('../../images/footer-book.png') no-repeat;
    z-index: 1;
  }
}

@media (max-width: $screen-sm-min) {
  .footer {
    width: 100%;
    .row {
      padding-top: 0;
    }
    .btn {
      display: table;
      margin: 0 auto;
      margin-left: -25px;
    }
    .book {
      top: 0;
      left: 0;
      width: 290px;
      height: 360px;
      background-size: 90%;
      margin-top: 80px;
    }
  }
}
/**
 * Navigation
 */

nav .navbar-collapse {
    margin-left: 0!important;
    margin-right: 0!important;
    padding-left: 15px;
    padding-right: 15px;
    right: 0;
    bottom: 0;
    top: 80px;
    overflow: auto
}

nav .navbar-toggle.collapsed .icon-bar:nth-child(3) {
    width: 75%
}
nav .navbar-toggle.collapsed .icon-bar:last-child {
    width: 50%
}
nav .navbar-toggle .icon-bar {
    background: #FFF;
    transition: 120ms width cubic-bezier(.46, 0, .62, 1);
    border-radius: 999px;
    height: 3px
}
nav .navbar-toggle:hover .icon-bar:last-child,
nav .navbar-toggle:hover .icon-bar:nth-child(3) {
    width: 100%
}

nav a:active,
nav a:focus,
nav a:hover {
    background: 0 0!important;
    color: #FFF
}
nav a.btn-danger:active,
nav a.btn-danger:focus,
nav a.btn-danger:hover {
    background: #ec2829!important;
    color: #FFF
}

.play-button:hover {
  animation: hvr-pulse 1s linear infinite;
}

.btn:hover, a:not(.right):not(.left):hover  {
    -webkit-animation-name: hvr-pop;
    animation-name: hvr-pop;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

@media (max-width: $screen-sm-min) {
  .navbar-nav {
    font-family: cabinbold;
    margin-left: -15px !important;
    margin-bottom: 50px;
    li {
      text-align: left;
      a {
        padding-left: 0;
      }
    }
  }
}

@media (max-width: $screen-md-max) {
  .navbar-nav {
    font-family: cabinbold;
    li {
      margin-left: 0;
      margin-right: 0;
      a {
        padding-left: 0;
        padding-right: 0;
        margin: 0;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 799px) {
  .navbar-nav {
    font-family: cabinbold;
    font-size: 16pt;
    li {
      margin-left: 0;
      margin-right: 15px !important;
      font-size: 15pt;
      &:last-child {
        margin-right: 0 !important;
      }
      .btn {
        width: 90px !important;
        font-size: 14pt !important;
        float: left !important;
      }
      a {
        font-size: 15pt;
        padding-left: 0;
        padding-right: 0;
        margin: 0;
      }
    }
  }
  #intro h2 {
    margin-top: 100px;
    font-size: 32pt;
    line-height: 32pt;
  }
  #intro .book {
    height: 490px;
    background-size: 50%;
    margin-left: 50px;
    &:after {
      top: 415px;
      left: -80px;
      background-size: 90%;
    }
  }
  #intro .tower-hospital {
    top: -90px;
    left: 300px;
    width: 350px;
    height: 580px;
    background-size: 100%;
  }
  #intro .amazon .btn {
    display: table;
    margin: 0 auto;
  }
  .footer {
    .row {
      min-height: 365px;
    }
    h2 {
      position: static;
    }
    .book {
      margin: 0;
      right: 0;
    }
  }
}

@media (min-width: 800px) and (max-width: 1024px) {
  .navbar-nav {
    li {
      margin-right: 15px !important;
    }
    a {
      font-size: 13pt;
    }
    .btn-buynow {
      width: 100px !important;
      font-size: 13pt !important;
    }
  }
  .footer .book {
    right: 0 !important;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  #table-of-content .row img+h2 {
    top: -20px;
  }
  .footer {
    h2 {
      font-size: 24pt !important;
    }
    .book {
      top: -350px !important;
      right: 0 !important;
      left: auto;
    }
  }
}

@media (min-width: 720px) and (max-width: 721px) {
  .navbar-nav {
    .btn-buynow {
      margin-left: -50px;
    }
  }
  #intro h2 {
    font-size: 32pt;
    line-height: 32pt;
  }
  #intro .book {
    height: 450px;
    background-size: 50%;
    margin-left: 50px;
    &:after {
      top: 380px;
      left: -80px;
      background-size: 90%;
    }
  }
  #intro .tower-hospital {
    top: -120px;
    left: 100px;
    width: 200px;
    height: 330px;
    background-size: 100%;
  }
  #intro .amazon .btn {
    display: table;
    margin: 0 auto;
  }
}

.popup {
  opacity: 1; 
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.6);
  transition: opacity ease .5s;
  z-index: 1031;
  .content {
    width: 100%;
    height: 100%;
    max-width: 800px;
    max-height: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10100;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .close {
      opacity: 1;
      position: absolute;
      top: 0;
      right: 0;
      font-family: cabinbold;
      color: #fff;
      background: #e62b1e;
      width: 25px;
      height: 25px;
      line-height: 25px;
      border-radius: 25px;
      font-size: 16pt;
      text-align: center;
      z-index: 1;
    }
  }
}